const headers = {
  addToCart: {
    Accept: 'application/json, text/javascript',
  },
  products: {
    'Content-Type': 'application/json',
    Accept: 'application/json, text/javascript, */*; q=0.01',
  },
};

export default headers;
